import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import "index.css";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
/*
import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
*/
import MainSections from "./Sections/MainSections.js";
//import SectionDownload from "./Sections/SectionDownload.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const brand = (
    <span
      style={{
        marginLeft: "-15px",
        fontFamily: "InformalRoman",
        fontWeight: "300",
        fontSize: "1.5em",
        opacity: "100%",
        //color: "#FFF",
      }}
    >
      &nbsp;VeraciTek&nbsp;
    </span>
  );
  let r = Math.floor(Math.random() * 5 + 2);
  return (
    <div>
      <Helmet>
        <title>VeraciTek Development Studio</title>
        <meta
          name="description"
          content="VeraciTek Application Development Studio. ve·rac·i·ty (vu-ras'i-tē)- Conformity to fact or truth; accuracy or precision."
        />
      </Helmet>
      <Header
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          opacity: "50%",
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/bg" + r + ".jpg")}>
        <div
          style={{
            fontFamily: "CourierNew",
            fontWeight: "900",
            position: "fixed",
            marginRight: "24px",
            top: "50%",
            right: "10px",
            minWidth: "200px",
            maxWidth: "1900px",
            textAlign: "right",
            width: "80%",
            display: "inline-flex",
            "--c": "calc(150vh - 200vw)",
            "--c2": "max(var(--c), var(--c)*-1)",
            "--c3": "calc(16px + (0.01*var(--c2)))",
            fontSize: "calc(16px + var(--c3))",
            marginTop: "calc(-28vh - var(--c3))",
            afontSize: "calc(16px + 6 * (100vw - 320px) / 105)",
            lineHeight: "0.9",
            float: "right",
            opacity: "20%",
            color: "white",
            //border: "solid",
          }}
        >
          {" "}
          ve·rac·i·ty
          <br />
          (vu-ras&apos;i-tē)- Conformity to fact or truth; accuracy or
          precision.
        </div>
        <div className={classes.container} style={{ marginTop: "-250px" }}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand} style={{ opacity: "80%" }}>
                <h1 className={classes.title} style={{ fontSize: "6vw" }}>
                  <img
                    style={{ width: "11vw", marginBottom: "-10px" }}
                    src={require("assets/img/logoLayout.png").default}
                  />
                </h1>
                <h3
                  className={classes.subtitle}
                  style={{ fontWeight: 400, fontStyle: "italic" }}
                >
                  &quot;...hard-to-find IT folks who still speak plain
                  English!&quot;
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        {/*
        <SectionBasics />
        <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        */}
        <MainSections />
      </div>
      <Footer />
    </div>
  );
}
