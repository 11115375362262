import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/logoLayoutAv.png";
import { Helmet } from "react-helmet";

//import Button from "components/CustomButtons/Button.js";
/*
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import NavPills from "components/NavPills/NavPills.js";
import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";
*/

import styles from "assets/jss/material-kit-react/views/AboutPage.js";

const useStyles = makeStyles(styles);

export default function aboutPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const brand = (
    <span
      style={{
        marginLeft: "-15px",
        fontFamily: "InformalRoman",
        fontWeight: "300",
        fontSize: "1.5em",
        opacity: "100%",
        //color: "#FFF",
      }}
    >
      &nbsp;VeraciTek&nbsp;
    </span>
  );
  //const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  let r = Math.floor(Math.random() * 5 + 2);
  return (
    <div>
      <Helmet>
        <title>Our Story</title>
        <meta
          name="description"
          content="Ryan Jameson founded VeraciTek in 1999 based on serving client computer needs since 1994. We refine our process to match your needs."
        />
      </Helmet>
      <Header
        color="transparent"
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg" + r + ".jpg")}>
        <div
          style={{
            fontFamily: "CourierNew",
            fontWeight: "900",
            position: "fixed",
            marginRight: "24px",
            top: "50%",
            right: "10px",
            minWidth: "200px",
            maxWidth: "1900px",
            textAlign: "right",
            width: "80%",
            display: "inline-flex",
            "--c": "calc(150vh - 200vw)",
            "--c2": "max(var(--c), var(--c)*-1)",
            "--c3": "calc(16px + (0.01*var(--c2)))",
            fontSize: "calc(16px + var(--c3))",
            marginTop: "calc(-13vh - var(--c3))",
            afontSize: "calc(16px + 6 * (100vw - 320px) / 105)",
            lineHeight: "0.9",
            float: "right",
            opacity: "20%",
            color: "white",
            //border: "solid",
          }}
        >
          {" "}
          ve·rac·i·ty
          <br />
          (vu-ras&apos;i-tē)- Conformity to fact or truth; accuracy or
          precision.
        </div>
        <div
          className={classes.container}
          style={{ fontSize: "vh", marginTop: "-180px" }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{ fontSize: "4vh" }}>
                &nbsp;
              </h1>
              <h4>&nbsp;</h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.container}>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>VeraciTek, Inc.</h3>
                    <h6>circa 1999</h6>
                    {/*
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Ryan Jameson founded VeraciTek in 1999 based on serving client
                computer needs since 1994.
                <br />
                <br />
                After meeting the needs of companies internationally, we&apos;ve
                discovered that many organizations have unique requirements and
                processes that merit custom applications to support them. Once
                built these products will serve for years and cost less than
                licensing a one-size-fits-all off-the-shelf product.
                <br />
                <br />
                Many organizations have internal requirements that are difficult
                to meet with off-the-shelf (OTS) programs and their own staff.
                VeraciTek provides a one-stop-shop for your development needs.
                We have assembled the best talent to meet those needs using the
                latest tools like ReactJS, Node, Microsoft Dynamics 365 CRM,
                Angular, .NET Core, SQL Server, MongoDB, and so many others.
                <br />
                <br />
                <br />
              </p>
            </div>
            {/*
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Studio",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Work",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Favorite",
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
                </GridContainer> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
