import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/LetsTalkSections/workStyle.js";
const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  const [m, sm] = useState("");
  const [n, sn] = useState("");
  const [e, se] = useState("");
  const [t, st] = useState("");
  const [i, si] = useState("");

  useEffect(() => {
    if (e.length > 0) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(e)) {
        window.alert("valid email required");
      } else if (e.length > 0)
        si(
          <>
            &nbsp;&nbsp;&nbsp;
            <img
              style={{ marginTop: "-5px" }}
              src={
                "https://veracitek.com/m/confirmed.g?t=" +
                encodeURIComponent(t) +
                "&n=" +
                encodeURIComponent(n) +
                "&e=" +
                encodeURIComponent(e)
              }
            />
          </>
        );
    }
  }, [m]);

  return (
    <div className={classes.section} style={{ marginTop: "-160px" }}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <a name="liftoff"></a>
            Bring Your Story To Life
          </h2>
          <h4 className={classes.description}>
            You&apos;ve met our team, now let us meet yours. Just send us a note
            and we&apos;ll be in touch.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      sn(e.currentTarget.value);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    onChange: (e) => {
                      se(e.currentTarget.value);
                    },
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Include a note, optional."
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  onChange: (e) => {
                    st(e.currentTarget.value);
                  },
                  multiline: true,
                  rows: 5,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button
                  onClick={() => {
                    sm(Date.now);
                  }}
                  disabled={m > 0 ? true : false}
                  color="primary"
                >
                  Send Message
                  {i}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
