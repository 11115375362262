import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/LetsTalkSections/teamStyle.js";

import team1 from "assets/img/faces/ryan.jpg";
import team2 from "assets/img/faces/esther.jpg";
import team3 from "assets/img/faces/ryans.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section} style={{ marginTop: "-100px" }}>
      <h2 className={classes.title}>
        The &quot;hard-to-find IT Folks&quot; you&apos;ll probably meet first...
      </h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Ryan Jameson
                <br />
                <small className={classes.smallTitle}>DevOps (and ceo)</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  By 2023 Ryan will have 30 years of experience in nearly every
                  stage of the internet&apos;s development. That experience cuts
                  past the hype &amp; gets to the heart of your story &amp; the
                  best way to make it come true. Ryan still rolls up his sleeves
                  regularly to serve, stay aware and proficient in the newest
                  patterns &amp; products.
                </p>
              </CardBody>
              {/*
              <CardFooter className={classes.justifyCenter}>                
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </CardFooter>
              */}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Esther Tosini-Lohner
                <br />
                <small className={classes.smallTitle}>Scrum Master</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Esther is quite a world traveler &amp; critical thinker.
                  She&apos;ll hear your story &amp; learn it by heart. Then
                  she&apos;ll ensure that it comes to life just the way you
                  hope. Usually even better than you thought! Her contributions
                  to STEM disciplines like ours will bring a fabulous value
                  throughout the entire lifescycle of your story&apos;s
                  realization.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team3} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Ryan Seal
                <br />
                <small className={classes.smallTitle}>
                  Senior Full Stack Developer
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  &quot;Seal&quot; leads the charge on moving client stories
                  into the real world. His voracious appetite for powerful
                  developent tools translates into incredible productivity for
                  you as he leverages the team that is precisely matched to
                  &amp; for your vision.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
