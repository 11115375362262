import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/LetsTalk.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";

import { Helmet } from "react-helmet";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LetsTalk(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const brand = (
    <span
      style={{
        marginLeft: "-15px",
        fontFamily: "InformalRoman",
        fontWeight: "300",
        fontSize: "1.5em",
        opacity: "100%",
        //color: "#FFF",
      }}
    >
      &nbsp;VeraciTek&nbsp;
    </span>
  );
  let r = Math.floor(Math.random() * 5 + 2).toString();
  console.log(r);
  return (
    <div>
      <Helmet>
        <title>Your Story</title>
        <meta
          name="description"
          content="Our team becomes your team. We'll adapt to your environment &amp; ramp up quickly to bring your story to life. Be it filling any gaps, or running with things autonomously, however you prefer we are here to serve."
        />
      </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/bg" + r + ".jpg")}>
        <div
          style={{
            fontFamily: "CourierNew",
            fontWeight: "900",
            position: "fixed",
            marginRight: "24px",
            top: "50%",
            right: "10px",
            minWidth: "200px",
            maxWidth: "850px",
            textAlign: "right",
            width: "80%",
            display: "inline-flex",
            "--c": "calc(150vh - 200vw)",
            "--c2": "max(var(--c), var(--c)*-1)",
            "--c3": "calc(16px + (0.01*var(--c2)))",
            fontSize: "calc(16px + var(--c3))",
            marginTop: "calc(-28vh - var(--c3))",
            afontSize: "calc(16px + 6 * (100vw - 320px) / 105)",
            lineHeight: "0.9",
            float: "right",
            opacity: "20%",
            color: "white",
            //border: "solid",
          }}
        >
          {" "}
          sto·ry
          <br />
          (stȯr-ē)- a statement regarding the facts pertinent to a situation in
          question
        </div>
        <div
          className={classes.container}
          style={{ fontSize: "vh", marginTop: "-180px" }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{ fontSize: "3vh" }}>
                We Bring Your Stories Alive.
              </h1>
              <h4>
                Our team becomes your team. We{"'"}ll adapt to your environment
                {" &"} ramp up quickly to bring your story to life. Be it
                filling any gaps, or running with things autonomously; however
                you prefer we are here to serve.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
