import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import GitHub from "@material-ui/icons/GitHub";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import Update from "@material-ui/icons/Update";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/LetsTalkSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ marginTop: "-60px" }}>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Your Story</h2>
          <h5 className={classes.description}>
            Writing a computer program is like building a house, one size DOES
            NOT fit all. Everyone&apos;s story is different. If your needs are
            simple enough we can be your implementation partner with an OTS
            (off-the-shelf) product. From workstation to web VeraciTek
            specializes in meeting your specific needs. When software licensing
            and fees add up to more cost than having your own custom
            application, we can do that too.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Tier 3 Senior Engineers"
              description="A grand total of more than a century of experience among our senior technologists &amp; motivation too."
              icon={GitHub}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Diversity &amp; Platform Agnostic"
              description="When invited into all stages of your story VeraciTek will demonstrate proven experience and save your story from common pitfalls. When brought in to suppliment your team we adapt &amp; excel."
              icon={Update}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Tier 2 Work Ethic &amp; Productivity"
              description="From Scrum Master to Junior Dev, VeraciTek lives up to our namesake."
              icon={FitnessCenter}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
